<template>
 <!-- <Banner
    v-if="
      isLocal === 'true' &&
        sandbox === 'false' &&
        this.$route.path !== '/login' &&
        this.$route.name !== '404'
    "
  /> -->
 <div
  class="relative flex bg-gray-100 min_height_app"
  :class="
   dark
    ? 'dark transition-all transition-slowest ease'
    : 'light transition-all transition-slowest ease'
  ">
  <Sidebar
   @openSideNavHover="openSideNavHover($event)"
   @keepOpenSideNavHover="
    (sideNavHover = true), (hideNoNav = true), (showSideNav = true)
   "
   :navigation="navigation"
   :secondaryNavigation="secondaryNavigation"
   :specialNavigation="specialNavigation"
   :showSpecialNavigation="showSpecialNavigation"
   :statisticsAvailableForProduction="statisticsAvailableForProduction"
   v-if="this.$route.path !== '/login' && this.$route.name !== '404'"
   @openNewSidebar="openSide = !openSide"
   @closeSubmenu="openSubmenu = false"
   :openSubmenu="openSubmenu" />

  <div class="flex flex-col flex-1 overflow-hidden">
   <!-- Primary column -->
   <MainHeader
    v-if="this.$route.path !== '/login' && this.$route.name !== '404'"
    @openSubmenu="openSubmenu = true"
    :callerNumberNotFound="callerNumberNotFound"
    :callerNumber="callerNumber" />
   <div class="flex items-stretch flex-1 overflow-hidden">
    <!-- 
          For sidenav hover 
          @mouseleave="sideNavHover = false"

         -->
    <aside
     v-show="
      showSideNav &&
      this.$route.name !== 'Login' &&
      hasSideNav &&
      sideNavHover &&
      this.$route.name !== '404'
     "
     class="hidden overflow-y-auto border-t border-r border-gray-200 side_nav_width navbar lg:block dark:border-slate-400 dark:bg-slate-500">
     <SideNav
      @hasNavigation="hasSideNav = $event"
      @showSideNav="showSideNav = false"
      :navigation="
       sideHoverNav === 'sidebar.tickets.tickets'
        ? ticketNavigation
        : sideHoverNav === 'sidebar.survey.surveys'
        ? surveyNavigation
        : sideHoverNav === 'sidebar.tools.tools'
        ? toolsNavigation
        : sideHoverNav === 'sidebar.settings'
        ? configurationNavigation
        : sideHoverNav === 'sidebar.hosts.hosting'
        ? hostingNavigation
        : sideHoverNav === 'training'
        ? trainingNavigation
        : sideHoverNav === '3cxTools'
        ? statisticsNavigation
        : undefined
      " />
    </aside>
    <aside
     v-show="
      showSideNav &&
      this.$route.name !== 'Login' &&
      this.$route.name !== 'CustomerDetail' &&
      this.$route.name !== 'Home' &&
      this.$route.name !== 'Customer' &&
      this.$route.name !== 'Statistics' &&
      this.$route.name !== 'HomeCharts' &&
      !this.$route.path.includes('newHost') &&
      !this.$route.path.includes('new-infrastructure') &&
      !this.$route.path.includes('open-ticket') &&
      this.$route.name !== '404' &&
      hasSideNav &&
      !sideNavHover
     "
     class="hidden overflow-y-auto border-t border-r border-gray-200 side_nav_width navbar lg:block dark:border-slate-400 dark:bg-slate-500">
     <SideNav
      @hasNavigation="hasSideNav = $event"
      @showSideNav="showSideNav = false"
      v-if="this.$route.name"
      :navigation="
       (this.$route.name.includes('Ticket') ||
        this.$route.name.includes('IncomingEmails')) &&
       !this.$route.path.includes('support')
        ? ticketNavigation
        : this.$route.name.includes('Survey')
        ? surveyNavigation
        : this.$route.path.includes('support') ||
          this.$route.path.includes('configuration')
        ? configurationNavigation
        : this.$route.path.includes('tools') &&
          !this.$route.path.includes('3cx')
        ? toolsNavigation
        : this.$route.path.includes('hosting') &&
          !this.$route.path.includes('configuration') &&
          !this.$route.path.includes('newHost')
        ? hostingNavigation
        : this.$route.name.includes('Training')
        ? trainingNavigation
        : this.$route.name.includes('Statistics')
        ? statisticsNavigation
        : undefined
      " />
    </aside>
    <aside
     v-show="
      !showSideNav &&
      this.$route.name !== 'Login' &&
      this.$route.name !== '404' &&
      hasSideNav
     "
     class="hidden overflow-y-auto border-t border-r border-gray-200 dark:bg-slate-500 side_nav_off_width navbar lg:block">
     <SideNavOff @showSideNav="showSideNav = true" />
    </aside>
    <main class="flex-1 overflow-y-hidden lg:max-w-auto lg:mx-auto">
     <!-- Primary column -->
     <section
      aria-labelledby="primary-heading"
      :class="[
       this.$route.name !== 'Login' &&
       this.$route.name !== 'CustomerDetail' &&
       this.$route.name !== 'RecurringsDetailFolder' &&
       this.$route.name !== 'InitialsDetailFolder' &&
       this.$route.name !== 'LicencesDetailFolder' &&
       this.$route.name !== 'Home' &&
       this.$route.name !== 'Customer' &&
       this.$route.name !== 'Statistics' &&
       this.$route.name !== 'HomeCharts' &&
       this.$route.name !== 'UserPage' &&
       this.$route.name !== 'pbx3cxHosts' &&
       this.$route.name !== 'pbx3cxDashboard' &&
       this.$route.name !== 'pbx3cxHostsCreateSelectClient' &&
       this.$route.name !== 'pbx3cxHostsEditSelectClient' &&
       this.$route.name !== 'pbx3cxHostsEdit' &&
       this.$route.name !== 'pbx3cxAuditsCreate' &&
       this.$route.name !== 'pbx3cxAuditsEdit' &&
       this.$route.name !== 'pbx3cxHostsCreate' &&
       !this.$route.path.includes('newHost') &&
       !this.$route.path.includes('new-infrastructure') &&
       !this.$route.path.includes('open-ticket') &&
       this.$route.name !== '404' &&
       showSideNav
        ? 'main__sidenav--on'
        : this.$route.name !== 'Login' &&
          this.$route.name !== 'CustomerDetail' &&
          this.$route.name !== 'RecurringsDetailFolder' &&
          this.$route.name !== 'InitialsDetailFolder' &&
          this.$route.name !== 'LicencesDetailFolder' &&
          this.$route.name !== 'Home' &&
          this.$route.name !== 'Customer' &&
          this.$route.name !== 'Statistics' &&
          this.$route.name !== 'HomeCharts' &&
          this.$route.name !== 'UserPage' &&
          this.$route.name !== 'pbx3cxHosts' &&
          this.$route.name !== 'pbx3cxDashboard' &&
          this.$route.name !== 'pbx3cxHostsCreateSelectClient' &&
          this.$route.name !== 'pbx3cxHostsEditSelectClient' &&
          this.$route.name !== 'pbx3cxHostsCreate' &&
          this.$route.name !== 'pbx3cxHostsEdit' &&
          this.$route.name !== 'pbx3cxAuditsCreate' &&
          this.$route.name !== 'pbx3cxAuditsEdit' &&
          !this.$route.path.includes('newHost') &&
          !this.$route.path.includes('new-infrastructure') &&
          !this.$route.path.includes('open-ticket') &&
          this.$route.name !== '404' &&
          !showSideNav
        ? 'main__sidenav--off'
        : this.$route.name !== 'Login' && this.$route.name !== '404'
        ? 'main__no_sidenav'
        : '',
       'min-w-0 flex-1 h-full flex flex-col lg:order-last',
      ]">
      <SuccessBanner
       :key="key"
       :msg="successMsg"
       :active="activeBanner"
       @closeBanner="activeBanner = false"
       :isSuccess="isSuccess" />
      <router-view
       :key="$route.fullPath"
       @getTicketCount="getTicketsCount($event)"
       @setLoading="isLoading = $event"
       @activeBanner="(activeBanner = true), (key = !key)"
       @successMsg="successMsg = $event"
       @success="isSuccess = $event"
       @callerNumberNotFound="
        (callerNumberNotFound = true), (callerNumber = $event)
       "
       @getModules="getModules()" />
     </section>
    </main>
   </div>
  </div>
 </div>
 <loading
  v-model:active="isLoading"
  color="#327F84"
  :can-cancel="false"
  :on-cancel="onCancel"
  :is-full-page="true" />
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import SuccessBanner from "./components/SuccessBanner.vue";
import MainHeader from "./components/MainHeader.vue";
import Sidebar from "./components/Sidebar.vue";
import Banner from "./components/Banner.vue";
import SideNav from "./components/SideNav.vue";
import SideNavOff from "./components/SideNavOff.vue";

const sandbox = localStorage.getItem("sandbox");
const isLocal = localStorage.getItem("isLocal");

const navigation = [
 //  {
 //   name: "sidebar.hosts.hosting",
 //   // module: "hosting",
 //   show: false,
 //   href: "/hosting/hosts",
 //   children: [],
 //   icon: "server",
 //   modules: "edition-3cx",
 //   current: false,
 //   hasSideNav: true,
 //  },
 {
  name: "Dashboard",
  show: false,
  href: "/pbx3cx/dashboard",
  children: [],
  icon: "chart-line",
  current: false,
  hasSideNav: true,
  modules: "edition-3cx",
 },
 {
  name: "3cx Hosts",
  show: false,
  href: "/pbx3cx/hosts/1",
  children: [],
  icon: "table-list",
  current: false,
  hasSideNav: true,
  modules: "edition-3cx",
 },
 {
  name: "sidebar.tickets.tickets",
  show: false,
  href: "/tickets",
  children: [
   { name: "sidebar.tickets.open", href: "/tickets" },
   { name: "ticket.header.ticketsUnassigned", href: "/unassigned-tickets" },
   { name: "ticket.header.ticketsforTheDay", href: "/day-tickets" },
   { name: "ticket.header.ticketsForTheWeek", href: "/week-tickets" },
   { name: "ticket.header.myTickets", href: "/my-tickets" },
   //  { name: "ticket.header.teamTickets", href: "/team-tickets" },
   { name: "sidebar.tickets.closed", href: "/closed-tickets/1" },
  ],
  icon: "headset",
  modules: "ticketing",
  current: false,
  hasSideNav: true,
 },
 {
  name: "sidebar.survey.surveys",
  show: false,
  href: "/reply-surveys/1",
  icon: "clipboard-list",
  children: [
   { name: "sidebar.survey.replies", href: "/reply-surveys/1" },
   { name: "sidebar.survey.all", href: "/all-surveys/1" },
  ],
  modules: "communication",
  current: false,
  hasSideNav: true,
 },
 {
  name: "sidebar.tools.tools",
  modules: "communication",
  show: false,
  href: "/tools/sftp-accounts",
  icon: "wrench",
  current: false,
  hasSideNav: true,
 },
];

const specialNavigation = [
 {
  name: "training",
  show: false,
  href: "/training/courses",
  icon: "graduation-cap",
  modules: "training",
  children: [
   { name: "programs", href: "/training/programs" },
   { name: "courses", href: "/training/courses" },
   { name: "quizzes", href: "/training/quizzes" },
  ],
  current: false,
  hasSideNav: true,
 },
 {
  name: "3cxTools",
  show: false,
  href: "/3cx-tools/call-logs",
  children: [
   { name: "callLogs", href: "/3cx-tools/call-logs" },
   {
    name: "pbx3cxHosts",
    href: "/3cx-tools/hosts",
   },
  ],
  icon: "toolbox",
  modules: "edition-3cx",
  current: false,
  hasSideNav: false,
 },
 {
  name: "sidebar.settings",
  modules: "communication",
  show: false,
  href: "/support/tickets-categories",
  icon: "gear",
  children: [
   { name: "sidebar.tickets.templates", href: "/tickets-templates" },
   {
    name: "sidebar.support.ticketCategories",
    href: "/support/tickets-categories",
   },
   {
    name: "sidebar.support.ticketStatuses",
    href: "/support/tickets-statuses",
   },
   { name: "sidebar.support.emailFilters", href: "/support/email-filters" },
  ],
  current: false,
  hasSideNav: true,
 },
];

const hostingNavigation = [
 { name: "sidebar.hosts.hosts", href: "/hosting/hosts" },
 { name: "sidebar.hosts.infrastructure", href: "/hosting/infrastructure" },
];

const toolsNavigation = [
 {
  name: "Procédures",
  href: "/support-sheets",
 },
 {
  name: "sidebar.tools.sftpAccounts",
  href: "/tools/sftp-accounts",
 },
];

const ticketNavigation = [
 { name: "sidebar.tickets.open", href: "/tickets", number: undefined },
 {
  name: "ticket.header.ticketsUnassigned",
  href: "/unassigned-tickets",
  number: undefined,
 },
 {
  name: "ticket.header.ticketsforTheDay",
  href: "/day-tickets",
  number: undefined,
 },
 {
  name: "ticket.header.ticketsForTheWeek",
  href: "/week-tickets",
  number: undefined,
 },
 { name: "ticket.header.myTickets", href: "/my-tickets", number: undefined },
 {
  name: "ticket.header.teamTickets",
  href: "/team-tickets",
  number: undefined,
 },
 { name: "alertTickets", href: "/alert-tickets", number: undefined },
 {
  name: "sidebar.tickets.closed",
  href: "/closed-tickets/1",
  number: undefined,
 },
 {
  name: "statistics",
  href: "/tickets/statistics/general",
  children: [
   {
    name: "general",
    href: "/tickets/statistics/general",
   },
   {
    name: "statsPerUser",
    href: "/tickets/statistics/per-user",
   },
   {
    name: "perCustomer",
    href: "/tickets/statistics/per-customer",
   },
   {
    name: "partition",
    href: "/tickets/statistics/partition",
   },
   {
    name: "average",
    href: "/tickets/statistics/average",
   },
   {
    name: "dailyPointSummary",
    href: "/tickets/daily-point-summary",
   },
  ],
 },
 {
  name: "sidebar.tickets.incomingEmails",
  href: "",
  children: [
   {
    name: "sidebar.incomingEmails.unlinks",
    href: "/tickets/incoming-emails/unlink/1",
    number: undefined,
   },
   {
    name: "sidebar.incomingEmails.replied",
    href: "/tickets/incoming-emails/closed-tickets-replied/1",
    number: undefined,
   },
  ],
 },
];

const surveyNavigation = [
 { name: "sidebar.survey.replies", href: "/reply-surveys/1" },
 // { name: "export", href: "/reply-surveys/export" },
 // { name: "sidebar.survey.all", href: "/all-surveys/1" },
];

const trainingNavigation = [
 { name: "programs", href: "/training/programs" },
 { name: "courses", href: "/training/courses" },
 { name: "quizzes", href: "/training/quizzes" },
];

const configurationNavigation = [
 {
  name: "sidebar.tickets.tickets",
  show: false,
  href: "/support/tickets-categories",
  modules: "ticketing",
  children: [
   {
    name: "sidebar.support.supportTeam",
    href: "/support/team",
   },
   {
    name: "sidebar.tickets.templates",
    href: "/support/tickets-templates/ticket",
   },
   {
    name: "sidebar.support.ticketCategories",
    href: "/support/tickets-categories",
   },
   {
    name: "sidebar.support.ticketStatuses",
    href: "/support/tickets-statuses",
   },
   {
    name: "sidebar.configuration",
    href: "/support/tickets-configuration",
   },
   { name: "sidebar.support.emailFilters", href: "/support/email-filters" },
  ],
 },
 {
  name: "sidebar.hosts.hosting",
  href: "/configuration/hosts",
  show: false,
  modules: "edition-3cx",
  children: [
   {
    name: "sidebar.hosts.operatingSystems",
    href: "/configuration/hosting/hostOs",
   },
   {
    name: "sidebar.hosts.software",
    href: "/configuration/hosting/hostSoftwares",
   },
   { name: "sidebar.hosts.roles", href: "/configuration/hosting/hostRoles" },
   { name: "sidebar.hosts.types", href: "/configuration/hosting/hostTypes" },
   {
    name: "sidebar.hosts.shells",
    href: "/configuration/hosting/hostShells",
   },
   {
    name: "sidebar.hosts.functions",
    href: "/configuration/hosting/hostFunctions",
   },
   {
    name: "sidebar.hosts.supliers",
    href: "/configuration/hosting/hostSuppliers",
   },
   {
    name: "sidebar.hosts.datacenter",
    href: "/configuration/hosting/datacenters",
   },
   {
    name: "sidebar.hosts.hostServices",
    href: "/configuration/hosting/hostServices",
   },
  ],
 },
 //  {
 //   name: "pbx3cx",
 //   href: "/configuration/pbx3cx/hosts",
 //   children: [
 //    {
 //     name: "pbx3cxHosts",
 //     href: "/configuration/pbx3cx/hosts",
 //    },
 //   ],
 //  },
];

const statisticsNavigation = [
 {
  name: "callLogs",
  href: "/3cx-tools/call-logs",
 },
];

// const statisticsNavigation = [
//   {
//     name: "sidebar.tickets.tickets",
//     href: "/",
//     children: [
//       {
//         name: "statsPerUser",
//         href: "/",
//       },
//     ],
//   },
// ];

const secondaryNavigation = [];

const showSpecialNavigation = true;

const statisticsAvailableForProduction =
 process.env.VUE_APP_STATISTICS_PRODUCTION_AVAILABLE;

export default {
 title() {
  return `${localStorage.getItem("title")}`;
 },
 components: {
  MainHeader,
  Sidebar,
  Banner,
  SideNav,
  SideNavOff,
  Loading,
  SuccessBanner,
 },
 data() {
  return {
   isLoading: false,
   navigation,
   secondaryNavigation,
   specialNavigation,
   showSpecialNavigation,
   configurationNavigation,
   surveyNavigation,
   ticketNavigation,
   toolsNavigation,
   hostingNavigation,
   statisticsAvailableForProduction,
   sandbox,
   isLocal,
   cssPath: "",
   openSide: false,
   openSubmenu: false,
   showSideNav: true,
   hasSideNav: false,
   sideNavHover: false,
   sideHoverNav: "",
   hideNoNav: false,
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   trainingNavigation,
   statisticsNavigation,
   callerNumberNotFound: false,
   callerNumber: "",
   modules: {},
   filteredNavigation: [],
  };
 },
 methods: {
  initIcon(icon) {
   var link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
   link.type = "image/x-icon";
   link.rel = "shortcut icon";
   link.href = icon;
   document.getElementsByTagName("head")[0].appendChild(link);
  },
  openSideNavHover(event) {
   this.sideHoverNav = event;
   this.sideNavHover = true;
   this.hideNoNav = true;
   this.showSideNav = true;
  },
  showSubmenuTest(event) {
   console.log(event);
  },
  async getTicketsCount(myTicketCount) {
   if (this.token) {
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/tickets/counts`
     );
     console.log(
      "file: App.vue ~ line 407 ~ getTicketsCount ~ res.data",
      res.data
     );
     this.ticketNavigation.filter(r => {
      r.name.toLowerCase().includes("unassigned")
       ? (r.number = res.data.unassigned)
       : r.name.toLowerCase().includes("day")
       ? (r.number = res.data.daily)
       : r.name.toLowerCase().includes("week")
       ? (r.number = res.data.weekly)
       : r.name.toLowerCase().includes("open")
       ? (r.number = res.data.open)
       : r.name.toLowerCase().includes("closed")
       ? (r.number = res.data.closed)
       : r.name.toLowerCase().includes("myTickets")
       ? (r.number = res.data.assigned_to_myself)
       : "";
     });
     // this.ticketNavigation[0].number = res.data.open;
     // this.ticketNavigation[1].number = res.data.unassigned;
     // this.ticketNavigation[2].number = res.data.daily;
     // this.ticketNavigation[3].number = res.data.weekly;
     // this.ticketNavigation[4].number = res.data.assigned_to_myself;
     // this.ticketNavigation[5].number = res.data.closed;
    } catch (error) {
     this.errorHandling(error);
    }
   }
  },
  async getApi() {
   try {
    console.log(process.env.VUE_APP_API);

    /**
     * Local env development
     */
    if (process.env.VUE_APP_API) {
     console.log("LOCAL ENV");
     localStorage.setItem("isLocal", true);
     localStorage.setItem("sandbox", true);
     localStorage.setItem("title", "BlueRockTEL Admin");
     localStorage.setItem("language", "fr");
     localStorage.setItem("API", process.env.VUE_APP_API);
     localStorage.setItem(
      "logo",
      "https://storage.gra.cloud.ovh.net/v1/AUTH_9c30d35f284f44b2bda08609e7c19f33/bluerocktelclients/telecom0503/logo-horizontal.jpg"
     );
     this.$store.dispatch(
      "logo",
      "https://storage.gra.cloud.ovh.net/v1/AUTH_9c30d35f284f44b2bda08609e7c19f33/bluerocktelclients/telecom0503/logo-horizontal.jpg"
     );

     if (
      !this.$cookie.getCookie("API") ||
      this.$cookie.getCookie("API") !== process.env.VUE_APP_API
     ) {
      localStorage.removeItem("token");
      this.$store.dispatch("token", null);
      this.$router.push("/login");
      this.$cookie.setCookie("API", process.env.VUE_APP_API, {
       expire: "21d",
      });
     }
    } else {
     /**
      * Get infos from AirControl API
      */
     console.log("REMOTE ENV");
     axios
      .post("https://air-traffic.bluerocktel.net/api/flights")
      .then(res => {
       console.log(res.data[0]);
       let apiData = "";
       if (Object.keys(res.data[0]).length > 1) {
        // apiData = res.data[0].encom_admin_sanbox;
        apiData = res.data[0].encom_admin_production;
        localStorage.setItem("isLocal", true);
       } else {
        apiData = res.data[0].default;
        localStorage.setItem("isLocal", false);
       }
       if (apiData && apiData.active) {
        this.initIcon(apiData.logo);
        localStorage.setItem("sandbox", apiData.sandbox);
        localStorage.setItem("logo", apiData.logo);
        this.$store.dispatch("logo", apiData.logo);
        localStorage.setItem("title", apiData.title);
        if (apiData.background) {
         localStorage.setItem("background", apiData.background);
         this.$store.dispatch("background", apiData.background);
        }
        if (!localStorage.getItem("language")) {
         localStorage.setItem("language", apiData.lang);
        }
        if (
         !this.$cookie.getCookie("API") ||
         this.$cookie.getCookie("API") !== apiData.baseUrl
        ) {
         localStorage.removeItem("token");
         this.$store.dispatch("token", null);
         this.$router.push("/login");
         this.$cookie.setCookie("API", apiData.baseUrl, {
          expire: "21d",
         });
        }
       }
      })
      .then(res => {
       this.getTicketsCount();
       if (!this.modules) {
        this.getModules();
       }
      })
      .finally(() => {});
    }
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getModules() {
   this.isLoading = true;
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/modules`,
     {
      headers: {
       Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
     }
    );
    this.modules = res.data;
    console.log(res.data);
    const data = JSON.stringify(res.data);
    localStorage.setItem("modules", data);
    this.isLoading = false;
   } catch (error) {
    console.log("getModules ~ error:", error);
    this.isLoading = false;
    this.$emit("activeBanner");
    this.$emit("success", false);
    this.$emit("successMsg", error.response.data.message);
   }
  },
 },
 created() {
  this.getModules()
   .then(() => {
    this.navigation.forEach(item => {
     const show = this.modules[item.modules];
     item.show = typeof show === "boolean" ? show : true;
    });
    this.specialNavigation.forEach(item => {
     const show = this.modules[item.modules];
     item.show = typeof show === "boolean" ? show : true;
    });
    this.configurationNavigation.forEach(item => {
     const show = this.modules[item.modules];
     item.show = typeof show === "boolean" ? show : false;
    });
   })
   .catch(error => {
    console.error("Failed to fetch module:", error);
   });
 },
 mounted() {
  this.getApi();
  this.getModules();
 },
 computed: {
  ...mapGetters(["token", "dark", "modules"]),
 },
};
</script>

<style>
.side_nav_width {
 min-width: 15rem;
 overflow: hidden;
 position: fixed;
 min-height: 96%;
 top: 3rem;
 left: 5rem;
 z-index: 10;
}
.side_nav_off_width {
 width: 50px;
 overflow: hidden;
 position: fixed;
 min-height: 96%;
 top: 3rem;
 left: 5rem;
 z-index: 10;
}
.main__sidenav--on {
 margin-left: 0rem;
 margin-top: 3.4rem;
}
@media screen(md) {
 .main__sidenav--on {
  margin-left: 5rem;
 }
}
@media screen(lg) {
 .main__sidenav--on {
  margin-left: 20rem;
 }
}
.main__sidenav--off {
 margin-left: 8rem;
 margin-top: 3rem;
}
.main__no_sidenav {
 margin-left: 5rem;
 margin-top: 3rem;
}
html {
 scroll-behavior: smooth;
}
</style>
