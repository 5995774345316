import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

const routes = [
 /*  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
    },
  }, */
 { path: "/", redirect: "/tickets" },
 {
  path: "/tools/sftp-accounts",
  name: "SftpAccounts",
  component: () => import("../views/sftp/SftpAccounts.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/email-filters",
  name: "EmailFilters",
  component: () => import("../views/configuration/support/EmailFilters.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets",
  name: "Tickets",
  component: () => import("../views/tickets/Tickets.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/day-tickets",
  name: "DayTickets",
  component: () => import("../views/tickets/TicketsDay.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/week-tickets",
  name: "WeekTickets",
  component: () => import("../views/tickets/TicketsWeek.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/unassigned-tickets",
  name: "UnassignedTickets",
  component: () => import("../views/tickets/UnassignedTickets.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/my-tickets",
  name: "MyTickets",
  component: () => import("../views/tickets/MyTickets.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/alert-tickets",
  name: "AlertTickets",
  component: () => import("../views/tickets/AlertTickets.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/team-tickets",
  name: "TeamTickets",
  component: () => import("../views/tickets/TeamTickets.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/closed-tickets/:page",
  name: "ClosedTickets",
  component: () => import("../views/tickets/ClosedTickets.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/incoming-emails/unlink/:page",
  name: "OutTicketIncomingEmails",
  component: () =>
   import("../views/tickets/incomingEmails/OutTicketIncomingEmails.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/incoming-emails/closed-tickets-replied/:page",
  name: "ReplyToClosedTicketIncomingEmails",
  component: () =>
   import(
    "../views/tickets/incomingEmails/ReplyToClosedTicketIncomingEmails.vue"
   ),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/:id",
  name: "TicketDetail",
  component: () => import("../views/tickets/TicketDetailNew.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/daily-point-summary",
  name: "TicketDailyPointSummary",
  component: () => import("../views/tickets/DailyPointSummary.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/tickets-templates/:templateType",
  name: "TicketsTemplates",
  component: () => import("../views/tickets/TicketsTemplates.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/tickets-templates/:templateType/:id",
  name: "TicketTemplateDetail",
  component: () => import("../views/tickets/TicketTemplateDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customers/:page",
  name: "Customers",
  component: () => import("../views/customers/Customers.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:id",
  name: "CustomerDetail",
  component: () => import("../views/customers/CustomerDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },

 {
  path: "/customer-files/recurrings/:id",

  name: "RecurringsDetailFolder",
  component: () => import("../views/customers/RecurringsDetailFolder.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer-files/initials/:id",
  name: "InitialsDetailFolder",
  component: () => import("../views/customers/InitialsDetailFolder.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer-files/licences/:id",

  name: "LicencesDetailFolder",
  component: () => import("../views/customers/LicencesDetailFolder.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },

 {
  path: "/contract/:contractId",
  name: "ContractDetail",
  component: () => import("../views/customers/ContractDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/new-ticket",
  name: "NewTicket",
  component: () => import("../views/tickets/OpenNewTicket.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/open-ticket/:id/:contactId",
  name: "OpenTicket",
  component: () => import("../views/tickets/OpenNewTicket.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 // {
 //   path: "/edit-ticket/:ticketId/:customerId",
 //   name: "EditTicket",
 //   component: () => import("../components/tickets/EditTicket.vue"),
 //   props: true,
 //   meta: {
 //     requiresAuth: true,
 //   },
 // },
 {
  path: "/reply-surveys/:page",
  name: "ReplySurveys",
  component: () => import("../views/surveys/ReplySurveys.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/all-surveys/:page",
  name: "AllSurveys",
  component: () => import("../views/surveys/AllSurveys.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/survey-detail/:id",
  name: "SurveyDetail",
  component: () => import("../views/surveys/ReplySurveysDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/tickets-categories",
  name: "TicketCategories",
  component: () =>
   import("../views/configuration/support/TicketCategories.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/tickets-categories/:categoryId/:categoryName",
  name: "TicketSubCategories",
  component: () =>
   import("../views/configuration/support/TicketSubCategories.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/tickets-statuses",
  name: "TicketStatuses",
  component: () => import("../views/configuration/support/TicketStatus.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/tickets-configuration",
  name: "TicketConfiguration",
  component: () =>
   import("../views/configuration/support/TicketConfiguration.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/team",
  name: "TicketSupportTeam",
  component: () =>
   import("../views/configuration/support/TicketSupportTeam.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support/team/:id",
  name: "TicketSupportTeamDetail",
  component: () =>
   import("../components/configuration/support/TicketSupportTeamDetail.vue"),
  props: true,
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/hosting/hosts",
  name: "Hosts",
  component: () => import("../views/hosting/Hosts.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/hosting/hosts/:hostId",
  name: "HostDetail",
  props: true,
  component: () => import("../views/hosting/HostDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/hosting/newHost/:id",
  name: "NewHost",
  props: true,
  component: () => import("../views/hosting/NewHost.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/hosting/new-infrastructure/:id",
  name: "NewInfrastructure",
  props: true,
  component: () => import("../views/hosting/NewInfraView.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/configuration/hosting/:endPoint",
  name: "HostingConfiguration",
  props: true,
  component: () =>
   import("../views/configuration/hosting/HostingConfiguration.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/pbx3cx/hosts/:page",
  name: "pbx3cxHosts",
  props: true,
  component: () => import("../views/configuration/pbx3cx/pbx3cxHosts.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/pbx3cx/dashboard",
  name: "pbx3cxDashboard",
  props: true,
  component: () => import("../views/configuration/pbx3cx/pbx3cxDashboard.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/pbx3cx/hosts/create",
  name: "pbx3cxHostsCreateSelectClient",
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxHostsCreateSelectClient.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/pbx3cx/hosts/:id/:hostName/edit",
  name: "pbx3cxHostsEditSelectClient",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxHostsCreateSelectClient.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/:customerAccount/pbx3cx-hosts/create",
  name: "pbx3cxHostsCreate",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxHostsCreate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/pbx3cx-hosts/:customerAccount/:id/:hostName/edit",
  name: "pbx3cxHostsEdit",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxHostsCreate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/pbx3cx-hosts/:hostName/audits/create",
  name: "pbx3cxAuditsCreate",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxAuditsCreate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/pbx3cx-hosts/:hostName/audits/:id/edit",
  name: "pbx3cxAuditsEdit",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxAuditsCreate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/pbx3cx-hosts/:hostName/notes/create",
  name: "pbx3cxNotesCreate",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxNotesCreate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/pbx3cx-hosts/:hostName/notes/:id/edit",
  name: "pbx3cxNotesEdit",
  props: true,
  component: () =>
   import("../views/configuration/pbx3cx/Pbx3cxNotesCreate.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/hosting/infrastructure",
  name: "Infrastructures",
  component: () => import("../views/hosting/Infrastructure.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer",
  name: "Customer",
  component: () => import("../views/customers/Customer.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/contact/:id",
  name: "EditContact",
  props: true,
  component: () => import("../views/customers/Contact.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/customer/:customerId/contact",
  name: "Contact",
  props: true,
  component: () => import("../views/customers/Contact.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/pbx-3cx-hosts",
  name: "Pbx3cxHosts",
  component: () => import("../views/Pbx3cxHosts.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/call-logs",
  name: "Statistics",
  component: () => import("../views/statistics/Statistics.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/statistics/per-user",
  name: "TicketsStatistics",
  component: () => import("../views/statistics/SupportStatistics.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/statistics/general",
  name: "TicketsGeneralStatistics",
  component: () => import("../views/statistics/TicketingGeneralStatistics.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/statistics/per-customer",
  name: "TicketsPerCustomerStatistics",
  component: () =>
   import("../views/statistics/TicketingPerCustomerStatistics.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/statistics/average",
  name: "TicketsAvgStatistics",
  component: () => import("../views/statistics/TicketingAvgStatistics.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/tickets/statistics/partition",
  name: "TicketsPartitionStatistics",
  component: () =>
   import("../views/statistics/TicketingPartitionStatistics.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/home-charts",
  name: "HomeCharts",
  component: () => import("../views/HomeCharts.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support-sheets",
  name: "SupportSheets",
  component: () => import("../views/support/SupportSheets.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/support-sheets/:id",
  props: true,
  name: "SupportSheetDetail",
  component: () => import("../views/support/SupportSheetDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/programs",
  props: true,
  name: "TrainingPrograms",
  component: () => import("../views/training/Programs.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/programs/program-detail/:id",
  props: true,
  name: "TrainingProgramDetail",
  component: () => import("../views/training/ProgramDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/new-program",
  props: true,
  name: "TrainingNewProgram",
  component: () => import("../views/training/NewProgram.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/edit-program/:id",
  props: true,
  name: "TrainingEditProgram",
  component: () => import("../views/training/EditProgram.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/courses",
  props: true,
  name: "TrainingCourses",
  component: () => import("../views/training/Courses.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/new-course",
  props: true,
  name: "TrainingNewCourse",
  component: () => import("../views/training/NewCourse.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/edit-course/:id",
  props: true,
  name: "TrainingEditCourse",
  component: () => import("../views/training/EditCourse.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/courses/course-detail/:id",
  props: true,
  name: "TrainingCourseDetail",
  component: () => import("../views/training/CourseDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/courses/course-preview/:id",
  props: true,
  name: "TrainingCoursePreview",
  component: () => import("../views/training/CoursePreview.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/lesson/:lessonId",
  props: true,
  name: "TrainingLessonDetail",
  component: () => import("../views/training/LessonDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/quizzes",
  props: true,
  name: "TrainingQuizzes",
  component: () => import("../views/training/Quizzes.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/quizzes/quiz-detail/:id",
  props: true,
  name: "TrainingQuizDetail",
  component: () => import("../views/training/QuizDetail.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/quizzes/:id/new-quiz-group/step/:step",
  props: true,
  name: "TrainingNewQuizGroup",
  component: () => import("../views/training/NewQuizGroup.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/quizzes/:id/new-quiz-group/:quizGroupId/step/:step",
  props: true,
  name: "TrainingNewQuizGroupQuestions",
  component: () => import("../views/training/NewQuizGroup.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/training/quizzes/:id/quiz-group/:quizGroupId",
  props: true,
  name: "TrainingQuizGroupDetail",
  component: () => import("../views/training/EditQuizGroup.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/user-page",
  props: true,
  name: "UserPage",
  component: () => import("../views/user/UserPage.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/3cx-tools/call-logs",
  props: true,
  name: "StatisticsCallLogs",
  component: () => import("../views/statistics/CallLog.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 {
  path: "/person/number/:number",
  props: true,
  name: "CallerPage",
  component: () => import("../views/user/CallerPage.vue"),
  meta: {
   requiresAuth: true,
  },
 },
 // {
 //   path: "/hosts/:customerId",
 //   name: "Hosts",
 //   props: true,
 //   component: () => import("../views/hosting/Hosts.vue"),
 //   meta: {
 //     requiresAuth: true,
 //   },
 // },
 {
  path: "/login",
  name: "Login",
  component: () => import("../views/Login.vue"),
  meta: {
   requiresNotLogged: true,
  },
 },
 {
  path: "/:pathMatch(.*)*",
  name: "404",
  component: () => import("../components/Error404.vue"),
  meta: {
   requiresAuth: true,
  },
 },
];

let entryUrl = null;

const router = createRouter({
 history: createWebHistory(process.env.BASE_URL),
 routes,
});

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresAuth)) {
  if (store.state.token) {
   if (entryUrl) {
    const url = entryUrl;
    entryUrl = null;
    return next(url);
   }
   next();
   return;
  }
  entryUrl = to.path;
  next("/login");
 } else {
  next();
 }
});

router.beforeEach((to, from, next) => {
 if (to.matched.some(record => record.meta.requiresNotLogged)) {
  if (!store.state.token) {
   next();
   return;
  }
  next("/");
 } else {
  next();
 }
});

export default router;
