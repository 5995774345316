export default {
 data() {
  return {
   time: "",
  };
 },
 methods: {
  timeFormater(time) {
   // if (time > 3600) {
   //   return new Date(time * 1000).toISOString().slice(11, 19);
   // } else {
   return new Date(time * 1000).toISOString().slice(11, 19);
   // }
  },
  toLocaleDateString(date) {
   const dateCreated = new Date(date);

   const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
   };
   return dateCreated.toLocaleDateString("fr-FR", options);
  },
  toLocaleTimeString(time) {
   const dateCreated = new Date(time);
   const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
   };
   return dateCreated.toLocaleTimeString("fr-FR", options);
  },
  formatDateAndTime(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear() +
    " at " +
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2) +
    ":" +
    ("0" + dateCreated.getSeconds()).slice(-2);

   return formatedDate;
  },
  formatDate(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getDate()).slice(-2) +
    "/" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "/" +
    dateCreated.getFullYear();

   return formatedDate;
  },
  formatDateDash(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    dateCreated.getFullYear() +
    "-" +
    ("0" + (dateCreated.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + dateCreated.getDate()).slice(-2);

   return formatedDate;
  },
  getDateHourMinuteSeconds(date) {
   const dateCreated = new Date(date);
   const formatedDate =
    ("0" + dateCreated.getHours()).slice(-2) +
    ":" +
    ("0" + dateCreated.getMinutes()).slice(-2) +
    ":" +
    ("0" + dateCreated.getSeconds()).slice(-2);

   return formatedDate;
  },
  minuteToDaysHoursMinutes(time) {
   return time / 24 / 60 + ":" + ((time / 60) % 24) + ":" + (time % 60);
  },
  ucfirst(str) {
   return str.charAt(0).toUpperCase() + str.slice(1);
  },
  __(key, ucFirst = true, params = null) {
   return ucFirst ? this.ucfirst(this.$t(key)) : this.$t(key, params);
  },
  parseDateDayMonthYearHifen(date) {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let day = ("0" + newDate.getDate()).slice(-2);
    // let hours = ("0" + newDate.getHours()).slice(-2);
    // let minutes = ("0" + newDate.getMinutes()).slice(-2);
    // let seconds = ("0" + newDate.getSeconds()).slice(-2);
    let fullDate = year + "-" + month + "-" + day;
    return String(fullDate);
   },
   getFirstDayOfMonth(year, month) {
    let date = new Date(year, parseInt(month) - 1, 1);
    return date;
   },
   getLastDayOfMonth(year, month) {
    let date = new Date(year, parseInt(month), 0);
    return date;
   },
   daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
   },
   formatDateApiRequest(date) {
    let newDate = new Date(date);
    let year = newDate.getFullYear();
    let month = ("0" + (newDate.getMonth() + 1)).slice(-2);
    let day = ("0" + newDate.getDate()).slice(-2);
    let formated = String(year) + String(month) + String(day);
    return formated;
   },
   formatDateHyphen(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
   },
  getCurrentDay() {
   const today = new Date();
   return {
    firstDay: this.formatDateHyphen(today),
    lastDay: this.formatDateHyphen(today),
   };
  },
  getCurrentWeek() {
   const today = new Date();
   const firstDay = new Date(today.setDate(today.getDate() - today.getDay()));
   const lastDay = new Date(
    today.setDate(today.getDate() - today.getDay() + 6)
   );
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLastWeek() {
   const today = new Date();
   const lastWeekFirstDay = new Date(today);
   lastWeekFirstDay.setDate(today.getDate() - today.getDay() - 6); // Adjust for the first day of the week
   const lastWeekLastDay = new Date(today);
   lastWeekLastDay.setDate(today.getDate() - today.getDay() - 1);
   return {
    firstDay: this.formatDateHyphen(lastWeekFirstDay),
    lastDay: this.formatDateHyphen(lastWeekLastDay),
   };
  },
  getCurrentMonth() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLastMonth() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 1, 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth(), 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLast3Months() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 3, 1);
   console.log("getLast3Months ~ firstDay:", firstDay)
   const lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0);
   console.log("getLast3Months ~ lastDay:", lastDay)
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
  getLast6Months() {
   const today = new Date();
   const firstDay = new Date(today.getFullYear(), today.getMonth() - 6, 1);
   const lastDay = new Date(today.getFullYear(), today.getMonth()+1, 0);
   return {
    firstDay: this.formatDateHyphen(firstDay),
    lastDay: this.formatDateHyphen(lastDay),
   };
  },
 },
};
